<template>
  <div>

  </div>
</template>

<script>

export default {
  name: 'LoginComponent',

  mounted() {
  },

  computed: {

  },

  methods: {
  }
}


</script>

<style>

</style>
